/* Change this file to get your personal Portfolio */

import emoji from "react-easy-emoji";

// Your Summary And Greeting Section

const greeting = {
  username: "Patrick Odey",
  title: "Hi, I'm Patrick",
  subTitle: emoji(
    "Full-Stack Web Developer | Mobile App Developer | UI/UX designer"
  ),
  resumeLink:
    "https://drive.google.com/file/d/1RY8XGf2dVtK53n30fwJlFDERlgZfYoxA/view?usp=sharing",
};

// Your Social Media Link

const socialMediaLinks = {
  github: "https://github.com/odeypatrick",
  linkedin: "https://www.linkedin.com/in/patrick-odey-joseph/",
  gmail: "odeydev@gmail.com",
  facebook: "",
  twitter: "https://twitter.com/patrickodeyy"
};

// Your Skills Section

const skillsSection = {
  title: "What I do",
  subTitle:
    "DEVELOPING HIGH QUALITY WEB AND MOBILE APPLICATIONS",
  skills: [
    emoji(
      "⚡ Designing and developing very interactive frontend applications"
    ),
    emoji("⚡ Developing back-end servers"),
    emoji(
      "⚡ Working with technologies like Vue.js, React.js, Nodejs(express), MongoDB, Laravel etc"
    ),
    emoji(
      "⚡ Building very interactive mobile applications"
    ),
  ],

  softwareSkills: [
    {
      skillName: "python",
      classname: "logos:python",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "javascript",
      classname: "logos:javascript",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "reactJS",
      classname: "simple-icons:react",
      style: {
        color: "#61DAFB",
      },
    },
    {
      skillName: "vueJS",
      classname: "logos:vue",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "git",
      classname: "logos:git-icon",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Nodejs",
      classname: "logos:nodejs",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Electron",
      classname: "logos:electron",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Laravel",
      classname: "simple-icons:laravel",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "mongoDB",
      classname: "simple-icons:mongodb",
      style: {
        backgroundColor: "white",
        color: "green",
      },
    },
    {
      skillName: "Flutter",
      classname: "simple-icons:flutter",
      style: {
        backgroundColor: "transparent",
        color: 'blue'
      },
    },
  ],
};

// Your top 3 work experiences

const workExperiences = {
  viewExperiences: false, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Junior Developer",
      company: "NITDA",
      companylogo: require("./assets/images/NITDA-Logo.png"),
      date: "May 2021 – October 2021",
      // desc:
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      // descBullets: [
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      // ],
    },
    // {
    //   role: "Senior Software Engineer",
    //   company: "HupDev Technologies",
    //   companylogo: require("./assets/images/hupdev.jpg"),
    //   date: "August 2022 – Present",
      
    // },
    {
      role: "Web developer Lead",
      company: "Linkmeup LLC",
      companylogo: require("./assets/images/lmu.jpg"),
      date: "June 2022 – Present",
      // desc:
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      role: "Backend Developer Lead",
      company: "UrbanDrive Limited",
      companylogo: require("./assets/images/ub1.jpg"),
      date: "Contract",
      // desc:
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to false
  display: true, // Set false to hide this section, defaults to true
};

// Your certifications

const certifications = {
  certifications: [
    {
      title: "Become a Software Developer",
      subtitle: "- LinkedIn Learning",
      logo_path: "linkedin-learning.jpg",
      certificate_link:
        "https://drive.google.com/file/d/1k_-Rn6DTH1WN15KXI46oT8jUXgOwYX_g/view?usp=sharing",
      alt_name: "Linkedin",
      color_code: "#F6EAEA",
    },
    {
      title: "SQL Programming",
      subtitle: "- LinkedIn Learning",
      logo_path: "linkedin-learning.jpg",
      certificate_link:
        "https://drive.google.com/file/d/1CJHaHzD_Lo2IMhwPIbmHO5KQkZuTlZQv/view?usp=sharing",
      alt_name: "Experitest",
      color_code: "#F0FFDD",
    },
    {
      title: "Project Management",
      subtitle: "- LinkedIn Learning",
      logo_path: "linkedin-learning.jpg",
      certificate_link:
        "https://drive.google.com/file/d/1Cnq_tXqXaeNLey9Vz8E-CYLiyb80dX0X/view?usp=sharing",
      alt_name: "Pierian-Data",
      color_code: "#FEF6E6",
    },
    {
      title: "Python",
      subtitle: "- LinkedIn Learning",
      logo_path: "linkedin-learning.jpg",
      certificate_link:
        "https://drive.google.com/file/d/1Oh6NMW0Tejwg4wz36aCBAp3VSw5LBdoo/view?usp=sharing",
      alt_name: "RahulShettyAcademy",
      color_code: "#FDE9EA",
    },
  ],
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",

  blogs: [
    {
      url:
        "https://blog.bharatkammakatla.com/selenium-python-automation-framework-how-to-build-cke5vij9p012h9ds1fsaocyax",
      title: "How to add google login to your Nodejs application",
      description:
        "Interested in learning how to add google login to your node app? If so, check out here !",
    },
    {
      url:
        "https://blog.bharatkammakatla.com/steps-to-build-a-selenium-java-framework-ckdsi73xg005ga9s1defhemfz",
      title: "How to develop a chat app like Messenger",
      description:
        "Interested in building a chat app like Messenger? If so, check out here !",
    },
    {
      url:
        "https://blog.bharatkammakatla.com/face-expression-recognition-using-keras-and-cnns-ckbevmufo0195dis1dfs10kt1",
      title: "Building a Video Conferencing App like Zoom",
      description:
        "Are you interested in building a Video Conferencing App like Zoom? If so, check out here !",
    },
    {
      url:
        "https://medium.com/@bharatkammakatla/basic-end-to-end-scikit-learn-workflow-cd9e9a50491b?source=friends_link&sk=b828ac262c172abd7508efcbd00e5bd3",
      title: "Learn how to deploy your Mobile applications on Google PlayStore",
      description:
        "Do you want to know how a Mobile application is deployed on Google PlayStore? If so, check out here !",
    },
  ],
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+234 802 1302 662",
  email_address: "odeydev@gmail.com",
};

export {
  greeting,
  socialMediaLinks,
  skillsSection,
  workExperiences,
  openSource,
  blogSection,
  contactInfo,
  certifications,
};
